<template>

    <ModalLogin :show="showModal" @close="showModal = false"></ModalLogin>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">




        <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-2 px-2 shadow sm:rounded-lg sm:px-10">

                <form v-on:submit.prevent="onSubmit" class="space-y-6">
                    <div class="mt-1">
                        <div class="relative w-full mb-3">
                            Hola {{ datosUsuario.nombreUsuario }}
                            Hola {{ datosUsuario.nombreUsuario }}
                        </div>
                        <div class="relative w-full mb-3">

                            <label for="password0" class="block text-sm font-medium text-gray-700">
                                Ingrese Password Actual <span class="text-red-500">{{ err_pass_0 }}</span>
                                Ingrese Password Actual <span class="text-red-500">{{ err_pass_0 }}</span>
                            </label>
                            <input v-on:blur="chkpass" id="password0" v-model="password0" name="password0" :type="type0"
                                autocomplete="current-password" required=""
                                class="border-1 p-4 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" />
                            <div class=" absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                                <p class=" mt-5"> <a @click="showPassword0">{{ btnText0 }}</a></p>
                            </div>
                        </div>


                    </div>

                    <div>

                        <div class="mt-1">

                            <div class="relative w-full mb-3">

                                <label for="password1" class="block text-sm font-medium text-gray-700">
                                    Ingrese Nueva Password <span class="text-red-500">{{ err_pass_1 }}</span>
                                    Ingrese Nueva Password <span class="text-red-500">{{ err_pass_1 }}</span>
                                </label>

                                <input id="password1" v-model="password1" name="password1" :type="type1"
                                    autocomplete="current-password" required=""
                                    class="border-1 p-4 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" />
                                <div class=" absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                                    <p class=" mt-5"> <a @click="showPassword1">{{ btnText1 }}</a></p>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div>

                        <div class="mt-1">




                            <div class="relative w-full mb-3">
                                <label for="password2" class="block text-sm font-medium text-gray-700">
                                    Confirme Nueva Password <span class="text-red-500">{{ err_pass_2 }}</span>
                                    Confirme Nueva Password <span class="text-red-500">{{ err_pass_2 }}</span>
                                </label>
                                <input id="password2" v-model="password2" name="password2" :type="type2"
                                    autocomplete="current-password" required=""
                                    class="border-1 p-4 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" />
                                <div class=" absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
                                    <p class=" mt-5"> <a @click="showPassword2">{{ btnText2 }}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="mt-2 text-sm text-red-500">{{ err_pass }}</p>
                    </div>
                    <div>
                        <button type="button" @click="handleSubmit"
                            class="  w-full   flex   justify-center  rounded-md  border-gray-300 shadow-sm   py-4   px-4   border border-transparent
                   text-sm  font-medium  text-white   bg-indigo-600   hover:bg-indigo-700  focus:outline-none  focus:ring- focus:ring-offset-2    focus:ring-indigo-500  ">
                            Confirmar Cambio
                        </button>

                        <button type="button" class="w-full   flex   justify-center  rounded-md  border-gray-300 shadow-sm py-4 my-4   px-4   border border-transparent
                    text-sm  font-medium    bg-white   focus:ring- focus:ring-offset-2 " @click="return_home"
                            ref="cancelButtonRef">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import DOMPurify from 'dompurify'; 
import router from "../../router";
import ModalLogin from "../login/ModalLogin";
import { mapGetters } from "vuex";
export default {

    name: "FormCambiarPass",
    mounted() {
        if (!this.user) {
            router.push("/expire");
        }
        console.log("user", this.user)
        console.log("user", this.user)
        this.datosUsuario = {

            nombreUsuario: this.user.info.nombres + " " + this.user.info.apellido1,
            userName: this.user.info.userName,
            idEmpresa: this.user.info.idEmpresa,
        };
    },
    data() {
        return {

            password0: "",
            password1: "",
            password2: "",
            showModal: false,
            type0: 'password',
            type1: 'password',
            type2: 'password',
            btnText0: 'Mostrar',
            btnText1: 'Mostrar',
            btnText2: 'Mostrar',
            err_pass: "",
            err_pass_0: "",
            err_pass_1: "",
            err_pass_2: "",
            datosUsuario: {

                nombreUsuario: '',
                userName:'',
                idEmpresa: ''
            }
        };
    },
    components: {
        ModalLogin,
    },
    methods: {
        return_home() {
            router.push('/home')
        },
        updModal() {
            this.showModal = true;
        },
        showPassword0() {
            if (this.type0 === 'password') {
                this.type0 = 'text'
                this.btnText0 = 'Ocultar'
            } else {
                this.type0 = 'password'
                this.btnText0 = 'Mostrar'
            }
        },
        showPassword1() {
            if (this.type1 === 'password') {
                this.type1 = 'text'
                this.btnText1 = 'Ocultar'
            } else {
                this.type1 = 'password'
                this.btnText1 = 'Mostrar'
            }
        },
        showPassword2() {
            if (this.type2 === 'password') {
                this.type2 = 'text'
                this.btnText2 = 'Ocultar'
            } else {
                this.type2 = 'password'
                this.btnText2 = 'Mostrar'
            }
        },
        validaPassword() {
            if (this.password0.length == 0) {
                this.err_pass_0 = "*"
                this.err_pass = 'Password original requerida'
                return false;
            }
            if (this.password1.length == 0) {
                this.err_pass_1 = "*"
                this.err_pass = 'Ingrese nueva password'
                return false;
            }
            if (this.password1 !== this.password2) {
                this.err_pass_1 = "*"
                this.err_pass_2 = "*"
                this.err_pass = 'passwords no coinciden'
                return false;
            }


            var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
            if (regex.test(this.password1)) {
                console.log('formato permitido')
                this.err_pass = ''
                console.log('ok!');
                return true;
            } else {
                this.err_pass = "8 caracteres. al menos un numero, una mayúscula y un caracter especial"
            }
            return false;
        },


        handleSubmit() {
            if (this.validaPassword()) {
                this.update()
            }
        },
        async update() {
            try {
                this.err_pass_0 = "*"
                this.err_pass_1 = "*"
                this.err_pass_2 = "*"

                 
                const apiKey = this.$store.state.login.tokenLogin;
                const data = {
                    oldPassword: this.password0,
                    newPassword: this.password1,
                
                };

                let url = 'api/Usuarios/CambiaPass';
                const resp = await axios.post(url, data, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${apiKey}`
                    }
                });

                switch (resp.status) {
                    case 200:
                        console.log(resp);

                        router.push("/home");
                        break;
                }
            } catch (err) {

                console.log(err);
            }
        },
        async chkpass() {
            try {


                let e = DOMPurify.sanitize(this.datosUsuario.userName);
                let p = DOMPurify.sanitize(this.password0);
                await this.$store.dispatch('login', { e, p });
                if (this.$store.state.login.statusLogin == 200) {
                    console.log('OK CON LOGIN')
                } else {
                    this.err_pass_0 = "  ( Password no válida )"
                }


            } catch (err) {
                this.updModal();
                console.log(err);
                this.mensaje = err;

            }


 
        }
    },
    computed: {
        ...mapGetters(["user"]),
    }
};
</script>

<template>
  <div class="p-0 mt-3">
   
    <VueTailwindDatepicker 
    :autoApply="true" :shortcuts="false" :useRange="true" i18n="es" :formatter="formatter"
      :startFrom="startFrom"  
      v-model="dateValue" />


  </div>
</template>


<script>
import { ref } from 'vue'
import VueTailwindDatepicker from 'vue-tailwind-datepicker'


export default {
  components: {
    VueTailwindDatepicker
  },
  props: ['fechasSeleccionadas'],
  emits: ['getFecCalendar'],
  mounted() {

  },
  data() {
    return {
      showRegularDate: true,
      checkin: '',

      startFrom: '',
      dateValue:  ref(''),
      formatter: ref({
        date: 'DD MM YYYY',
        month: 'MMM',

      }),


    }
  },
  watch: {
    fechasSeleccionadas(value) {
      this.dateValue = []
      this.startFrom = value[0]
      this.dateValue = value

    },
    dateValue() {

      this.getFecCalendar();
    },
  },
  methods: {
    closeDatePickerPopup() {
   
    
      // here you have access to this.regularDate
      // and you could do whatever you want with this.regularDate
    },
    getFecCalendar() {

      console.log(this.dateValue, "<--");
      this.$emit("getFecCalendar", this.dateValue)


    },
  },
}
</script>
<template>
  <div>
 

    <div v-if="dataLoaded" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-8">
      {{ error_load }}    
     
     <!-- <div v-for="objJaula in  this.objJaulas" :key="objJaula.id"
        class="bg-white w-100 overflow-hidden   shadow-md rounded-md mb-4 p-0">
-->
        <div 
        v-for="objJaula in this.$store.getters.getJaulas.filter(jaula => jaula.visible === true)" 
        :key="objJaula.id"
        class="bg-white w-100 overflow-hidden   shadow-md rounded-md mb-4 p-0">
       

        <div class="p-1"   >
          <div class="flex items-center">
            <div class="flex-shrink-0"></div>
            <div class="ml-0 flex-1 text-left">
              <dl>
                <dd class="mt-0 text-xs">
                  <GraficoUnidad :objJaula="objJaula"></GraficoUnidad>
                </dd>
              </dl>
            </div>
          </div>
        

      </div>
      </div>

    </div>
  </div>
</template>
<script>


import { mapGetters } from "vuex";


import GraficoUnidad from "./GraficoUnidad";


export default {
  name: "FaenasCliente",
  props: ["idEmpresa", "propIdFaena", "numRan","propObjJaulas","storeJaulas"],
  emits: ["chgFaena"],
  components: {
    GraficoUnidad,


  },
  mounted() 
  {
    this.idFaena=this.propIdFaena;
    this.dataLoaded = false
    this.getDivisionesApi();
    this.setpoint1 = 6.2;
    this.setpoint2 = 300;
    this.idEstado = 1;
 
    
    this.dataLoaded = true;  
   // this.loadUnidades();
    console.log("objJaulas:::::", this.objJaulas)
  },
  data() {
    return {
      dataList: [],
      dataLoaded: false,
      datos_mensuales: [],
      trenes: [],
      //objJaulas: [],
      objJaulas: [],
      cards: [{}],
      error_load: '',
      seriesFaenas: [],
      dots: ["green", "yellow", "green", "red"],
      setpoint1: 0,
      setpoint2: 0,
      idEstado: 0,
      idFaena: this.propIdFaena,
      show:false,
      f:''
    };
  },
  methods: {
    filtraVisibles(){
     
     // this.objJaulas = f.filter(item => item.visible === true);
    },
    loadUnidades(){
      this.f = this.$store.state.faenas;
     

      let obj = this.f.faenas.filter(item => item.id === this.idFaena);
      if (Array.isArray(obj[0].unidades)) {
        //this.objJaulas = obj[0].unidades.filter(unidad => unidad.natural !== 'S');

        this.objJaulas =  obj[0].unidades.map( unidad=>{
            if (unidad.natural !== 'S'){
             
                 return unidad;
               
            }
        })

        this.dataLoaded = true;  
       } 
    },  
    async getDivisionesApi() {
      //console.log("carga diviones", "id empresa");
      try {



        this.loadTree = true;
        this.loadMediciones = false;
        this.loadVistaplanta = false;





       

      } catch (err) {
        console.log(err);
      }
    },



  },
  computed: {
    ...mapGetters(["user", "token"]),

  },
  watch: {

    propIdFaena: function (value) {
      //this.dataLoaded = false;  
      //this.idFaena = value;
     // this.loadUnidades();
     console.log(value)
    },

    propObjJaulas: function (value) {
      
      //this.loadUnidades();
  
      
      this.filtraVisibles(value);
      this.dataLoaded = true;  
    },
    storeJaulas: function(value){
      alert('detecta cambios')
      console.log(value)
    }
  }

};
</script>

<template>


    <div ref="movingBackground" class="bg-container  min-h-screen flex flex-col  ">



        <!-- Header -->
        <header class="flex flex-wrap  mt-0 ">
            <div class="w-full  text-center">
               
                    <img  class="mx-auto block"  width="320" src="/images/logo-intro-ingenieria.png" />
              
            </div>
            <div class="w-full sm:w-1/1 items-left justify-left">
                <!-- Columna 1 -->
                <div class="  p-0 mt-3  ">
                    <h2 class="text-base text-3xl font-bold tracking-tight  leading-7 text-black"> 
                        PROYECTOS DEL ÁREA DE DESARROLLO
                    </h2>
                </div>

            </div>
           

        </header>

        <!-- Body -->
        <main class="flex-1 p-0 space-y-4">
           
            <div class="grid grid-cols-1  gap-4  ">


                <div class="mx-auto max-w-7xl px-6   text-center lg:px-8">

                    <div class="relative mt-6">
                        <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-20">
                            <div> 

                                
                                   <CardDark></CardDark>

                            </div>
                            <div>
                                    <CardLight></CardLight>
                            </div>

                            
                        </div>

                    </div>
                </div>



            </div>

            <!-- Segunda Grilla -->
            <div class="grid grid-cols-1    gap-0  ">


                <div class="mx-auto max-w-7xl px-0  mt-3 text-center lg:px-0">
                    <div class="mx-auto">
                        <h2 class="text-base text-3xl font-bold tracking-tight  leading-7 text-black"> 
                      APLICACIONES INDUSTRIALES
                    </h2>
                    </div>
                    <div class="relative mt-3">
                        <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-4 pb-4">
                            <div class=" p-0">
                              <CardAppDark></CardAppDark>



                            </div>
                            <div class=" p-0">
                                <CardAppLight></CardAppLight>

                            </div>

                        </div>

                    </div>
                    
                </div>



            </div>
        </main>

        <!-- Footer -->
        <footer class="bg-gray-800 text-white p-4">
          
        </footer>
    </div>
</template>
<script>
import CardDark from  "./CardDark.vue"
import CardLight from  "./CardLight.vue"
 
import CardAppLight from  "./CardAppLight.vue"
import CardAppDark from "./CardAppDark.vue";
export default {
    name: 'MovingBackground',
    components: {
        CardDark,
        CardLight,
 
        CardAppDark,
        CardAppLight
    },

    data() {
        return {
            position: 0, // Posición inicial del fondo
        };
    },
    mounted() {
        this.moveBackground();
    },
    methods: {
        moveBackground() {
            const bgContainer = this.$refs.movingBackground;
            const move = () => {
                this.position += 0.1;
                bgContainer.style.backgroundPosition = `${this.position}px 50%`;

                if (this.position > bgContainer.clientWidth) {
                    this.position = 0;
                }

                requestAnimationFrame(move);
            };

            move();
        },
    },
}
</script>

<style scoped>
.bg-container {
    width: 100%;

    background-image: url('/public/images/clouds-intro.png');
    background-size: cover;
    background-position: 0 50%;
    position: relative;
    overflow: hidden;
} 

</style>
<template>
  <div class="p-0 mt-3">
    
    <VueTailwindDatepicker 
      i18n="es"
      :shortcuts="false" 
     :formatter="formatter" 
      v-model="dateValue"  />


  </div>
</template>


<script>
import { ref } from 'vue'
import VueTailwindDatepicker from 'vue-tailwind-datepicker'


export default {
  components: {
    VueTailwindDatepicker
  },
  props: ['fechasSeleccionadas'],
  emits: ['getFecCalendar'],
  mounted() {
   
  },
  data() {
    return {
      showRegularDate: true,
      checkin: '',
      placeholder:'Seleccione dias',
      startFrom: '',
      dateValue:  ref(this.fechasSeleccionadas),
      formatter: ref({
        date: 'DD MM YYYY',
        month: 'MMM',

      }),


    }
  },
  watch: {
    fechasSeleccionadas(value) {
      console.log(value)
    },
    dateValue() {

      this.getFecCalendar();
    },
  },
  methods: {
    closeDatePickerPopup() {
   
    
      // here you have access to this.regularDate
      // and you could do whatever you want with this.regularDate
    },
    getFecCalendar() {

      console.log(this.dateValue, "<--");
      this.$emit("getFecCalendar", this.dateValue)


    },
  },
}
</script>
<template>
  <nav   class="mt-0   pb-0"  aria-label="Sidebar"   >
      <div class="px-0 space-y-1">
        <a
          @click="ExitCodAccion"
          class="cursor-pointer text-white group flex items-center px-2 py-0 text-sm leading-6 font-medium rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
            />
          </svg>
          <span class="pl-2"> Resumen</span>
        </a>
      </div>
    </nav>
    <nav
      class="pt-0 mt-0  pb-0 divide-y divide-cyan-800 overflow-y-auto"
      aria-label="Sidebar"
      @dragover="handleDragOVer"
      @dragenter="handleDragEnter"
      @drop="handleDrop"
    >
      <div v-for="f in this.$store.getters.getDroppables" :key="f.idFaena" class="mt-1 py-0">
        <div class="flex items-center"  
          :draggable="true"
          @drop="handleDrop(f.id)"
          @dragstart="handleDragFaena(f.id)"
          >
          
          <div class="w-1/2 text-left">
            <span
              @click="loadCentros(f)"
              class="cursor-pointer group flex items-center px-2 py-1 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
            >
              {{ f.nombre }}
            </span>
          </div>
          <div class="w-1/2 text-left">
            <span
              @click="loadHistorial(f)"
              class="cursor-pointer group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-yellow-400 hover:text-black hover:bg-yellow-300"
            >
              Historial
            </span>
          </div>
        </div>
      </div>
      
   
    </nav>
  <!----

    fragmento "draggables" suprimido en esta vista
  
 --->
   <nav
      class="mt-0 flex-1 flex flex-col divide-y divide-cyan-800 overflow-scroll"
      aria-label="Sidebar"
    >
   

   <div class="mt-0 pt-0">
        <div class="px-2 space-y-1">
          <a 
            v-for="item in secondaryNavigation"
            :key="item.name"
            :href="item.href"
            class="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
          >
            <component
              :is="item.icon"
              class="mr-4 h-6 w-6 text-cyan-200"
              aria-hidden="true"
            />
            {{ item.name }}
          </a>
        </div>
      </div>
   </nav>   
</template>
<script>
import { HomeIcon } from "@heroicons/vue/outline";

import { mapActions } from "vuex";
export default {
name: "SideBarCliente",
emits: ["chgFaenaCentros", "chgIdPantalla", "chgIdPantalla2"],
props: {
  navigation: {
    type: Object,
  },
  secondaryNavigation: {
    type: Object,
  },
  adminNavigation: {
    type: Object,
  },
  faenas: {
    type: Array,
  },
},
watch: {
  faenas: function (f) {
    let obj = [];
    let pos = 0;
    f.forEach((objeto) => {
      pos++;
      obj.push({ id: objeto.id, 
        nombre:objeto.nombre, 
        tipo:objeto.tipo,
        consumo_o2:objeto.consumo_o2 , 
        pos:pos });
    });
    // descomentar 
    //  this.$store.dispatch("draggableCreate", obj);
  },
},
components() {
  HomeIcon;
},
methods: {
  ...mapActions("draggdropp", ["draggableCreate"," setIdCurrentDrag"]),
  loadCentros(f) {
    // console.log("carga centros de Faena ", f.id, f.faena);
    //  this.$emit("chgFaenaCentros", f);
    this.$emit("chgCentros", f);
  },
  loadHistorial(f) {
    this.$emit("loadHistorial", f);
  },
  ExitCodAccion() {
    // console.log("chgIdPantalla2 ", 1);
    this.$emit("chgIdPantalla", { id: 1 });
  },
  handleDragOVer(event) {
    console.log("over");
    event.preventDefault();
  },
  handleDrop(id) {
    console.log("DROP en Faena", id);
    this.$store.dispatch("setPosCurrentDroppable", id);
  },
  handleDrag(id){
      console.log('tomando ',id)
      this.$store.dispatch("setIdCurrentDrag", id);
  },  
  handleDragFaena(id) {
    console.log("tomando faena ", id);
    this.$store.dispatch("setIdCurrentDroppable", id);
    this.isDragStart = true;
  },
  handleDragEnd(){
    console.log('fin del drag');
    this.$store.dispatch("setIdCurrentDrag", '');
  }
},
};
</script> 